export default function SloganUs (){
    return (
        <div className="about-free-area">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="about-fre-bottom text-center">
                            <h3>1000'den fazla öğrenci ile</h3>
                            <h2>16 Ülke 345 Program</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}