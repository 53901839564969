export default function BeforeUs (){
    return (
        <div className="about-camping-arrea pb-150">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-title text-center">
                            <p className="title">Yurtdışı Gezi Programımız</p>
                            <h2>Öğrenciler İçin Unutulmaz Bir Deneyim</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-sm-4">
                        <div className="single-about-feature">
                            <div className="about-fre-thumb">
                                <img src="img/icon/1.png" alt=""/>
                            </div>
                            <div className="about-fre-content">
                                <h4>Akademik Gezintiler</h4>
                                <p>Üniversite kampüsleri, araştırma merkezleri ve müzeleri ziyaret
                                    ederek öğrencilerin gelecekteki kariyerlerine yön vermelerine yardımcı oluyoruz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="single-about-feature">
                            <div className="about-fre-thumb">
                                <img src="img/icon/2.png" alt=""/>
                            </div>
                            <div className="about-fre-content">
                                <h4>Kültürel Etkinlikler</h4>
                                <p>Tiyatro oyunları, konserler, festivaller gibi etkinliklere
                                    katılarak öğrencilerin farklı kültürleri tanımalarını sağlıyoruz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="single-about-feature">
                            <div className="about-fre-thumb">
                                <img src="img/icon/3.png" alt=""/>
                            </div>
                            <div className="about-fre-content">
                                <h4>Sanatsal Atölyeler</h4>
                                <p>Resim, heykel, müzik gibi sanat dallarında atölye çalışmaları
                                    düzenleyerek öğrencilerin yaratıcılıklarını geliştiriyoruz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="single-about-feature">
                            <div className="about-fre-thumb">
                                <img src="img/icon/5.png" alt=""/>
                            </div>
                            <div className="about-fre-content">
                                <h4>Sosyal Etkinlikler</h4>
                                <p>Yerel halkla etkileşim fırsatları yaratarak öğrencilerin iletişim
                                    becerilerini güçlendiriyoruz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="single-about-feature">
                            <div className="about-fre-thumb">
                                <img src="img/icon/11.png" alt=""/>
                            </div>
                            <div className="about-fre-content">
                                <h4>Akademik Gezintiler</h4>
                                <p>Üniversite kampüsleri, araştırma merkezleri ve müzeleri ziyaret
                                    ederek öğrencilerin gelecekteki kariyerlerine yön vermelerine yardımcı oluyoruz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="single-about-feature">
                            <div className="about-fre-thumb">
                            <img src="img/icon/15.png" alt=""/>
                            </div>
                            <div className="about-fre-content">
                                <h4>Kültürel Etkinlikler</h4>
                                <p>Tiyatro oyunları, konserler, festivaller gibi etkinliklere
                                    katılarak öğrencilerin farklı kültürleri tanımalarını sağlıyoruz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="single-about-feature">
                            <div className="about-fre-thumb">
                                <img src="img/icon/4.png" alt=""/>
                            </div>
                            <div className="about-fre-content">
                                <h4>Sanatsal Atölyeler</h4>
                                <p>Resim, heykel, müzik gibi sanat dallarında atölye çalışmaları
                                    düzenleyerek öğrencilerin yaratıcılıklarını geliştiriyoruz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="single-about-feature">
                            <div className="about-fre-thumb">
                                <img src="img/icon/12.png" alt=""/>
                            </div>
                            <div className="about-fre-content">
                                <h4>Akademik Gezintiler</h4>
                                <p>Üniversite kampüsleri, araştırma merkezleri ve müzeleri ziyaret
                                    ederek öğrencilerin gelecekteki kariyerlerine yön vermelerine yardımcı oluyoruz.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}