import Header from "./components/Header";
import BreadcrumbArea from "./components/BreadcrumbArea";
import AdventureGridArea from "./adventure/AdventureGridArea";
import SubscribeArea from "./adventure/SubscribeArea";
import PartnerArea from "./components/PartnerArea";
import Footer from "./components/Footer";
import AdventureDetailPart from "./adventure-detail/AdventureDetailPart";

export default function AdventureDetail (){

    return (
        <div className="wrapper">

            <AdventureDetailPart/>



        </div>
    )
}