export default function WhyUs() {
    return (

        <div className="about-adventure-area pt-110 pb-150">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section-title text-center">
                            <p className="title">Yurtdışı Gezi Programımız</p>
                            <h2>Öğrenciler İçin Unutulmaz Bir Deneyim </h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <p className="text-center mb-45">


                            Campusorg olarak, lise öğrencilerine unutulmaz bir deneyim yaşatmak için yurtdışı
                            gezi programları düzenliyoruz. Öğrencilerimizin sadece akademik değil, aynı zamanda kültürel
                            ve sanatsal gelişimlerine de katkıda bulunmak önceliğimiz.<br/><br/>


                            Campusorg olarak amacımız, öğrencilerin sadece ders kitaplarından değil, aynı
                            zamanda gerçek hayattan da öğrenmelerini sağlamak. Yurtdışı gezilerimiz, öğrencilerin
                            özgüvenlerini artırmalarına, farklı kültürlere karşı hoşgörülü olmalarına ve geleceğe daha
                            donanımlı bir şekilde hazırlanmalarına katkı sağlıyor.<br/><br/>

                            Campusorg ile yurtdışı gezisi yapmak isteyen lise öğrencileri ve velileri için
                            unutulmaz bir deneyim garantisi veriyoruz.<br/>


                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-about-adventure text-center">
                            <div className="about-ad-thumb">
                                <img src="img/adventure/1.png" alt=""/>
                            </div>
                            <div className="about-ad-content">
                                <h4>Öğrenci Odaklı Yaklaşım</h4>
                                <p>Her öğrencinin farklı ihtiyaçlarını ve ilgi alanlarını göz önünde bulundurarak özel
                                    programlar hazırlıyoruz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-about-adventure text-center">
                            <div className="about-ad-thumb">
                                <img src="img/adventure/2.png" alt=""/>
                            </div>
                            <div className="about-ad-content">
                                <h4>Deneyimli Rehberler</h4>
                                <p>IAlanında uzman ve deneyimli rehberlerimiz, öğrencilerin hem güvenliği hem de
                                    öğrenimi için yanlarında oluyor.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-about-adventure text-center">
                            <div className="about-ad-thumb">
                                <img src="img/adventure/3.png" alt=""/>
                            </div>
                            <div className="about-ad-content">
                                <h4>Kapsamlı Gezi Rotaları</h4>
                                <p>ITarihi ve kültürel zenginlikleriyle öne çıkan şehirleri ziyaret ederek öğrencilerin
                                    ufkunu genişletiyoruz.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-about-adventure text-center">
                            <div className="about-ad-thumb">
                                <img src="img/adventure/1.png" alt=""/>
                            </div>
                            <div className="about-ad-content">
                                <h4>Dil Eğitimi</h4>
                                <p>İsteğe bağlı olarak dil eğitimi programları düzenleyerek öğrencilerin yabancı dil
                                    becerilerini geliştirmelerine yardımcı oluyoruz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-about-adventure text-center">
                            <div className="about-ad-thumb">
                                <img src="img/adventure/2.png" alt=""/>
                            </div>
                            <div className="about-ad-content">
                                <h4>Eğlenceli Aktiviteler</h4>
                                <p>Müzeler, sanat galerileri, tarihi yapılar gibi kültürel mekanların yanı sıra
                                    eğlenceli etkinliklerle dolu bir program sunuyoruz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-about-adventure text-center">
                            <div className="about-ad-thumb">
                                <img src="img/adventure/3.png" alt=""/>
                            </div>
                            <div className="about-ad-content">
                                <h4>Konaklama ve Yeme-İçme</h4>
                                <p>Öğrencilerimizin konforu için özenle seçilmiş konaklama tesisleri ve yerel
                                    lezzetlerin tadına varabilecekleri yemek seçenekleri sunuyoruz.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}