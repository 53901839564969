import {Link} from "react-router-dom";

export default function SingleTravelItemSmallMedium (){
    return (

        <div className="single-blog-post style-2">
            <div className="post-thumbnail">
                <img src="img/post/s5.jpg" alt=""/>
            </div>
            <div className="single-post-content-thumb">
                <div className="post-meta">
                    <span><i className="fal fa-clock"></i>Sep 11, 2024</span>
                    <span><i className="fal fa-user"></i>Liza De Villiers</span>
                    <span><i className="fad fa-eye"></i>180</span>
                    <span><i className="fal fa-comments"></i>12 Comments</span>
                </div>
                <div className="entry-header">
                    <Link to="blog-2.html#"><h2 className="entry-title">The Only Beach Packing List
                        You’ll Ever Need</h2></Link>
                </div>
                <div className="entry-content">
                    <p>It doesn’t matter if it’s just for a day or for an entire week… check out
                        this beach packing list and you’re sure to show up prepared. There’s
                        something rejuvenating about soaking up the sun, swimming in the ocean, and
                        basking in the sea breeze. If you live close enough to a beach that you can
                        take a day trip.</p>
                    <Link to="blog-2.html#" className="btn border-theme">View In Detail</Link>
                </div>
            </div>
        </div>

    )
}